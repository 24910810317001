import { REPORT_TYPES, ReportTypeValues } from "./report_types";
import { window } from "../../../global";

type ReportMenuItems = {
  liProps: {
    id: string;
  };
  to: string;
  route: string;
  children: string;
  show: boolean;
};

export const getReportTypeTitle = (reportType: ReportTypeValues | string) =>
  REPORT_TYPES[reportType]?.title || "";

export const getReportsMenuItems = (
  show: boolean,
  isV1: boolean,
): ReportMenuItems[] => {
  return Object.values(REPORT_TYPES).map((report) => ({
    liProps: {
      id: `nav-report-${report.name}`,
    },
    to: report.path,
    route:
      isV1 && !!report.v1Url
        ? report.v1Url.replace(".php", "")
        : report.path.slice(1),
    children: report.title,
    show,
  }));
};

export const getReportRoutes = (): string[] =>
  Object.values(REPORT_TYPES)
    .map((report) => [report.path.slice(1), report.v1Url?.replace(".php", "")])
    .filter(Boolean)
    .flat();

export const goReportV1 = (
  report_type: string,
  params?: Record<string, string>,
) => {
  let oldReportUrl = REPORT_TYPES[report_type].v1Url;
  if (!oldReportUrl) {
    window.open(`${window.location.origin}`, "_self");
  }
  if (params) {
    oldReportUrl += `?${new URLSearchParams(params).toString()}`;
  }
  window.open(`${window.location.origin}/${oldReportUrl}`, "_self");
};
