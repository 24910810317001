import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { oauth } from "../utils";
import { window } from "../global";
import { Report } from "../components/report/action-menu/types";
import { goReportV1 } from "../components/report/routes/helpers";
import { REPORT_TYPES } from "../components/report/routes/report_types";

interface FetchSavedReportsResponse {
  json: {
    reports: Report[];
  };
  response: Response;
}

interface SaveReportResponse {
  json: {
    report: Report;
  };
  response: Response;
}

type DeleteSavedReportResponse = FetchSavedReportsResponse;

export interface SavedReportsState {
  loading: boolean;
  reports: Report[];
  current_report: {
    id: string;
    name: string;
    type: string;
  };
  error?: string;
}

const initialState: SavedReportsState = {
  // @ts-ignore
  current_report: window?.initialState?.savedReports?.current_report || {
    id: "",
    name: "",
    type: "",
  },
  loading: false,
  // @ts-ignore
  reports: window?.initialState?.savedReports?.reports || [],
  error: undefined,
};

export const getSavedReportsState = (state): SavedReportsState =>
  state.savedReports;

const savedReportsSlice = createSlice({
  name: "savedReports",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setReports(state, action: PayloadAction<Report[]>) {
      state.reports = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setCurrentReport(state, action: PayloadAction<Partial<Report>>) {
      state.current_report = {
        id: action.payload.report_id,
        name: action.payload.report_name,
        type: action.payload.report_type_name,
      };
    },
    updateReport(state, action: PayloadAction<Report>) {
      state.reports = state.reports.map((report) =>
        report.report_id === action.payload.report_id
          ? { ...report, ...action.payload }
          : report,
      );
    },
  },
});

export const {
  setLoading,
  setReports,
  setError,
  setCurrentReport,
  updateReport,
} = savedReportsSlice.actions;

export const fetchSavedReports = () => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response: FetchSavedReportsResponse = await oauth(
      "GET",
      "report-collection/get-report-by-identity",
      {},
    );

    if (!response.json) {
      setError("Error fetching reports");
      return;
    }
    dispatch(
      setReports(
        response.json.reports.filter((r) => !!REPORT_TYPES[r.report_type_name]),
      ),
    );
  } catch (error) {
    setError(error.toString());
  } finally {
    dispatch(setLoading(false));
  }
};

export const loadSavedReport =
  (report_id?: string, report_type?: string, report_name?: string) => () => {
    if (report_id) {
      window.open(`/report/${report_id}`, "_self");
    } else {
      goReportV1(report_type, {
        executeFunction: "true",
        type: report_type,
        report_name,
      });
    }
  };

export const deleteSavedReport = (report_id: string) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response: DeleteSavedReportResponse = await oauth(
      "DELETE",
      `report-collection/${report_id}`,
    );

    if (!response.json) {
      setError(`Error deleting report`);
      console.log("Error deleting report", response.response);
      return;
    }

    dispatch(setReports(response.json.reports));
  } catch (error) {
    setError(error.toString());
  } finally {
    dispatch(setLoading(false));
  }
};

export const createSavedReport =
  (report_name: string, query: object, type: string) => async (dispatch) => {
    try {
      const res: SaveReportResponse = await oauth("POST", "report-collection", {
        report_filters: query,
        type,
        report_name,
      });

      if (!res.json) return;

      const { report } = res.json;
      dispatch(loadSavedReport(report.report_id));
    } catch (e) {
      setError(e.toString());
      console.log("Error saving report", e);
    }
  };

export const updateSavedReport =
  (report_id: string, report_name: string, query: object) =>
  async (dispatch) => {
    try {
      const res: SaveReportResponse = await oauth(
        "PUT",
        `report-collection/${report_id}`,
        {
          report_filters: query,
          report_name,
        },
      );

      if (!res.json) return;

      dispatch(setCurrentReport(res.json.report));
      dispatch(updateReport(res.json.report));
    } catch (e) {
      setError(e.toString());
      console.log("Error updating report", e);
    }
  };

export const getReportFilters = (report_id: string) => (state) =>
  state.savedReports.reports.find(
    (report: Report) => report.report_id === report_id,
  )?.report_filters;

export const clearCurrentReport = () => (dispatch) => {
  dispatch(
    setCurrentReport({ report_id: "", report_name: "", report_type_name: "" }),
  );
};

export default savedReportsSlice.reducer;
