import React from "react";
import { useDispatch } from "react-redux";
import { Button, colors, Csku, Popup, Text } from "@commonsku/styles";
import { deleteSavedReport } from "../../../redux/savedReports";
import { closePopup } from "../../../actions/popup";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";

interface DeleteReportPopupProps {
  report_id: string;
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .popup-title {
    color: ${colors.neutrals.bodyText};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .popup-buttons {
    margin-top: 24px;
    width: fit-content;
  }
`;

const DeleteReportPopup = ({ report_id }: DeleteReportPopupProps) => {
  const dispatch = useDispatch();

  const onPopupClose = () => dispatch(closePopup());

  return (
    <Popup
      overlayZIndex={BASE_ZINDEX + 101}
      zIndex={BASE_ZINDEX + 201}
      style={{ width: 446, height: "fit-content" }}
      noHeader
    >
      <StyledContainer>
        <Text className="popup-title">
          Are you sure you want to delete this saved report?
        </Text>
        <Csku className="popup-buttons">
          <Button variant="secondary" onClick={onPopupClose} mr={24}>
            Cancel
          </Button>
          <Button
            variant="error"
            onClick={() => {
              dispatch(deleteSavedReport(report_id));
              onPopupClose();
            }}
          >
            Delete
          </Button>
        </Csku>
      </StyledContainer>
    </Popup>
  );
};

export default DeleteReportPopup;
